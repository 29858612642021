import { useContext, useState } from "react";
import { appContext } from "../../context/context";
import useHandleError from "../useHandleError";
import axios from "axios";
import { BASE_URL, ENDPOINT_GET_TOKEN_VIA_APP_CREDENTIALS } from "../../constants/APIConstant";
import { useAlert } from "react-alert";

const useGetTokenViaAppCredentials = () => {
  const appState = useContext(appContext);
  const alert = useAlert();
  const handleError = useHandleError();
  const [loading, setLoading] = useState(false);
  const getTokenViaAppCredentials = async (
    msisdn,
    appName,
    appKey,
    callback
  ) => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${BASE_URL}${ENDPOINT_GET_TOKEN_VIA_APP_CREDENTIALS}`,
        {
          appName,
          appKey,
          msisdn,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const { message, tokenObj, userName } = response.data;
      const isUserSubscribed =
        tokenObj?.tokenSubscriptionVerified ||
        tokenObj?.isTokenSubscriptionVerified;

      appState.saveToken(tokenObj);
      if (userName) {
        appState.saveUserName(userName);
      }
      appState.saveUserAuthorized(isUserSubscribed);

      alert.success(message);
      callback(userName);
    } catch (error) {
      handleError(error);
    } finally {
      setLoading(false);
    }
  };

  return { getTokenViaAppCredentials, loading };
};

export default useGetTokenViaAppCredentials;
